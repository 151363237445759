import React from "react"
import { graphql } from 'gatsby'
import { useIntl } from "gatsby-plugin-intl"

import SEO from "../components/seo"
import JumboHeader from "../components/sections/JumboHeader/JumboHeader"
import ContactForm from "../components/sections/ContactForm/ContactForm"
import ThreeUp from "../components/sections/ThreeUp/ThreeUp"
import ImageText from "../components/sections/ImageText/ImageText"
import VideoText from "../components/sections/videoText/videoText"
import FullVideo from '../components/sections/fullVideo/fullVideo'
import ImageGallery from "../components/sections/imageGallery/imageGallery"
// import { connect } from 'react-redux'
// import { toggleDarkMode } from '../state/reducer'



const IndexPage = ({ data, isDarkMode, dispatch }) => {
  const JumbiImage = data.webdesigniphone.childImageSharp.fluid
  const templateModern = data.templateModern.childImageSharp.fluid
  const templateClassic = data.templateClassic.childImageSharp.fluid
  const editor = data.editor.childImageSharp.fluid
  const webDesign = data.webDesign.childImageSharp.fluid
  const i18n = useIntl().formatMessage

  console.log(data)
  return (
  <>
    <SEO 
      title="Home" 
      keywords={[`gatsby`, `application`, `react`]} />
    <JumboHeader 
      heading={i18n({ id: "pages.index.jumbo.title" })} 
      subtitle={i18n({ id: "pages.index.jumbo.subtitle" })} 
      text={i18n({ id: "pages.index.jumbo.description"})} 
      link={{linkTitle: i18n({ id: "pages.index.jumbo.linkText"}), slug: "/register"}} 
      boxTitle={i18n({ id: "pages.index.jumbo.price" })} 
      boxSubTitle={i18n({ id: "pages.index.jumbo.month" })} 
      freeSignUp={i18n({ id: "pages.index.jumbo.signup_free" })}
      boxTrialTitle={i18n({ id: "pages.index.jumbo.trial_period" })} 
      boxListItems={i18n({ id: "pages.index.jumbo.benefits" }).split(', ')} 
      image={JumbiImage}
    />
    <ImageText 
      image={webDesign} 
      textLeft={false} 
      title={i18n({ id: "pages.index.image_text.title" })}
      text={i18n({ id: "pages.index.image_text.text" })} 
      linkTitle={i18n({ id: "pages.index.image_text.link_title" })}
      link="/register" 
    />
    <ThreeUp 
      title={i18n({ id: "pages.index.three_up.title" })} 
      elements={
        [
          {
            title: i18n({ id: "pages.index.three_up.texts.1.title" }),
            text: i18n({ id: "pages.index.three_up.texts.1.text" })
          },
          {
            title: i18n({ id: "pages.index.three_up.texts.2.title" }),
            text: i18n({ id: "pages.index.three_up.texts.2.text" })
          },
          {
            title: i18n({ id: "pages.index.three_up.texts.3.title" }),
            text: i18n({ id: "pages.index.three_up.texts.3.text" })
          }
        ]
      }
      cta={[
        {link: "/register", linkTitle: "Prøv gratis"}
      ]}
    />
    <ImageGallery
      title={i18n({ id: "pages.index.image_gallery.title" })}
      text={i18n({ id: "pages.index.image_gallery.text" })}
      images={[{image: templateModern, title: "Moderne"}, {image: templateClassic, title: 'Klassisk'}]}
      />
    <VideoText 
      image={editor} 
      textLeft={true} 
      title={i18n({ id: "pages.index.video_text.title" })}
      text={i18n({ id: "pages.index.video_text.text" })} 
      linkTitle={i18n({ id: "pages.index.video_text.link_title" })}
      link="/register" 
    />
    <FullVideo 
      title={i18n({ id: "pages.index.full_video.title" })} 
      text={i18n({ id: "pages.index.full_video.text" })} 
      linkTitle={i18n({ id: "pages.index.full_video.link_title" })} 
      link="/register"
    />
    {/* <ContactForm /> */}
  </>
)
  }

// export default connect(state => ({
//   isDarkMode: state.app.isDarkMode}), null)(IndexPage)

export default IndexPage

export const query = graphql`
query {
  webdesigniphone: file(relativePath: { eq: "modern_design_mobile.png" }) {
    childImageSharp {
      fluid(maxWidth: 600, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  editor: file(relativePath: { eq: "editor.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  webDesign: file(relativePath: { eq: "classic_design_contact.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  templateModern: file(relativePath: { eq: "modern_design.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
  templateClassic: file(relativePath: { eq: "classic_design.png" }) {
    childImageSharp {
      fluid(maxWidth: 800, quality: 80) {
        base64
        aspectRatio
        src
        srcSet
        srcWebp
        srcSetWebp
        sizes
        presentationWidth
        presentationHeight
      }
    }
  }
}
`